<template>
    <section class="mt-4 mx-4">
        <div class="row mx-0 align-items-center mb-3">
            <div class="col-auto f-20 text-black f-600 px-0">
                Ventanas emergentes
            </div>
            <div class="col-auto ml-auto">
                <button type="button" class="btn-general btn btn-sm" @click="$router.push({name: 'ventanas-emergentes.crear-ventana'})">
                    Nueva ventana
                </button>
            </div>
            <div class="col-2">
                <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar" class="w-100" size="small" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="height: calc(100vh - 135px);">
            <div class="row mx-0">
                <div v-for="data in ventanasEmergentes.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="data.id" class="card-ventana p-2 cr-pointer mr-4 mb-4" @click="$router.push({name:'ventanas-emergentes.crear-ventana', params:{idEmergente:data.id}})">
                    <img :src="data.imagen" class="br-4" style="width:89px;height: 89px;" />
                    <div class="col px-2">
                        <p class="text-black f-17 f-500 tres-lineas"> {{ data.nombre }}</p>
                        <p class="f-15 text-black"> {{ formatearFecha( data.fecha_inicio, 'DD MMM yyyy - hh:mm a.' ) }} </p>
                        <p class="f-15 text-black"> {{ formatearFecha( data.fecha_fin, 'DD MMM yyyy - hh:mm a.' ) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import Emergente from '~/services/ventanas_emergentes'
export default{
    data(){
        return {
            buscar: '',
            ventanasEmergentes: [],
        }
    },
    mounted(){
        this.getVentanasEmergentes()
    },
    methods: {
        async getVentanasEmergentes(){
            try {
                const { data } = await Emergente.getEmergentes()
                this.ventanasEmergentes = data.arranques
            } catch (error){
                return this.errorCatch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.card-ventana{
	width: 330px;
	height: 106px;
	display: flex;
	border-radius: 8px;
	border: 1px solid #DBDBDB;
}
</style>